import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { FlexContainer } from "../components/FlexContainer";
import { Wrapper } from "../components/Wrapper";
import Menu from "./header/Menu";

export const MainLayout = () => {
  return (
    <Wrapper>
      <Menu />
      <FlexContainer $justify="center" $align="center" $direction="column">
        <WorkZone>
          <Outlet />
        </WorkZone>
      </FlexContainer>
    </Wrapper>
  );
};

const WorkZone = styled.section`
  max-width: 90%;
  width: 90%;
  margin-top: 25px;
`;
