import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  httpStatusCodes,
  REACT_CHANGE_SIGNATURE_URL,
} from "../../../../constant";

import { Block } from "../../../../components/Block.styled";
import { Button, LittleButton } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { myTheme } from "../../../../components/Theme.styled";
import { changeSignatureMode } from "../../../../redux/slices/actionsMySlice";
import { setError } from "../../../../redux/slices/errorSlice";
import { ShopWithErrorInterface } from "../../../../redux/slices/interface/resp/Shop.interface";
import { selectMySliceIsLoading } from "../../../../redux/slices/mySlice";
const _ = require("lodash");

const SignatureBlock = ({
  shop,
  userIdTg,
}: {
  shop: ShopWithErrorInterface;
  userIdTg: string;
}) => {
  const isLoading = useSelector(selectMySliceIsLoading);
  const dispatch = useDispatch();
  const [newWords, setNewWords] = useState<string[]>(
    shop ? [...shop.signatures] : []
  );
  const [word, setWord] = useState<string>("");

  const handleWordChange = (word: string) => {
    const updatedWords = newWords.includes(word)
      ? newWords.filter((item) => item !== word)
      : [...newWords, word];
    setNewWords(updatedWords);
  };

  const handleAddWord = () => {
    const trimmedWord = word.trim();
    if (trimmedWord && !newWords.includes(trimmedWord)) {
      if (newWords.length < 5) {
        setNewWords([...newWords, trimmedWord]);
        setWord("");
      } else {
        dispatch(
          setError({
            message: "Достигнут лимит в 6 подписей.",
            status: httpStatusCodes.BAD_REQUEST,
          })
        );
      }
    }
  };

  const saveWords = () => {
    if (newWords.length >= 6) {
      dispatch(
        setError({
          message: "Невозможно сохранить. Достигнут лимит в 6 подписей.",
          status: httpStatusCodes.BAD_REQUEST,
        })
      );
    } else {
      dispatch(
        changeSignatureMode({
          signatures: newWords,
          userIdTg: userIdTg,
          shopId: shop.id,
          url: REACT_CHANGE_SIGNATURE_URL,
        }) as any
      );
    }
  };

  const isChanged = !_.isEqual(newWords, shop.signatures);

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <h2>
          <b>Индивидуальные подписи</b>
        </h2>
        Ваши подписи для магазина: {shop.name}
        <StyledUl>
          {shop.signatures
            .concat(newWords.filter((word) => !shop.signatures.includes(word)))
            .map((word) => (
              <li key={word}>
                {newWords.includes(word) ? <>{word}</> : <s>{word}</s>}
                <LittleButton
                  $bgc="transparent"
                  color={`${myTheme.colors.light.primary}`}
                  onClick={() => handleWordChange(word)}
                >
                  {newWords.includes(word) ? "X" : "V"}
                </LittleButton>
              </li>
            ))}
        </StyledUl>
        <StyledInputInline type="button">
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            placeholder="Введите подпись"
          />
          <Button onClick={handleAddWord} disabled={!word.trim()}>
            Добавить
          </Button>
        </StyledInputInline>
        <Button
          $sizeButton="little"
          disabled={isLoading || !isChanged}
          onClick={saveWords}
        >
          Сохранить
        </Button>
      </FlexContainer>
    </Block>
  );
};

export default SignatureBlock;

const StyledUl = styled.ul`
  max-width: 100%;
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;
