import axios, { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { ActivePromocodeParamsInterface } from "./interface/req/ActivePromoParams.interface";
import { ChangeExWordsParamsInterface } from "./interface/req/ChangeExWordsParams.interface";
import { ChangeInstructionParamsInterface } from "./interface/req/ChangeInstructionParams.interface";
import { ChangeShopModeParamsInterface } from "./interface/req/ChangeShopModeParams.interface";
import { ChangeShopParamsInterface } from "./interface/req/ChangeShopParams.interface";
import { ChangeSignatureModeParamsInterface } from "./interface/req/ChangeSignificateModeParams.interface";
import { RegShopParamsInterface } from "./interface/req/CheckShopParams.interface";
import { CheckUserParamsInterface } from "./interface/req/CheckUserParams.interface";
import { DeleteShopParamsInterface } from "./interface/req/DeleteShopParams.interface";
import { FetchShopsParamsInterface } from "./interface/req/FetchShopsParams.interface";
import { FetchUserParamsInterface } from "./interface/req/FetchUserParams.interface";
import { ResponseInterface } from "./interface/resp/ResponseBase.interface";
import { ErrorCheckerInSlice } from "./tools/ErrorCheckerInSlice";
import { ErrorSwitchInSlice } from "./tools/ErrorSwitchInSlice";

export const fetchShops = createAsyncThunk(
  "shops/fetchShops",
  async ({ url, userIdTg }: FetchShopsParamsInterface, thunkAPI) => {
    try {
      const res: AxiosResponse = await axios.get(url, {
        params: {
          userIdTg,
        },
      });
      const result: ResponseInterface = res.data;

      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const regShop = createAsyncThunk(
  "shop/regShop",
  async ({ url, name, key, userIdTg }: RegShopParamsInterface, thunkAPI) => {
    try {
      const res = await axios.post(url, {
        name,
        key,
        userIdTg: userIdTg.toString(),
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const changeShop = createAsyncThunk(
  "shop/changeShop",
  async (
    { url, name, key, userIdTg, shopId }: ChangeShopParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        name,
        key,
        shopId,
        userIdTg,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);

export const changeShopMode = createAsyncThunk(
  "shop/changeShopMode",
  async (
    { url, timer, gptStars, userIdTg, shopId }: ChangeShopModeParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        timer,
        gptStars,
        shopId,
        userIdTg,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const changeSignatureMode = createAsyncThunk(
  "shop/changeSignatureMode",
  async (
    { url, signatures, userIdTg, shopId }: ChangeSignatureModeParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        signatures,
        shopId,
        userIdTg,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);

export const deleteShop = createAsyncThunk(
  "shop/deleteShop",
  async ({ url, userIdTg, shopId }: DeleteShopParamsInterface, thunkAPI) => {
    try {
      const res = await axios.post(url, {
        shopId,
        userIdTg,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const updateExWords = createAsyncThunk(
  "words/updateExWords",
  async ({ url, userIdTg, words }: ChangeExWordsParamsInterface, thunkAPI) => {
    try {
      const res = await axios.post(url, {
        userIdTg,
        words,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const updateInstruction = createAsyncThunk(
  "words/updateInstruction",
  async (
    { url, userIdTg, instruction }: ChangeInstructionParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        userIdTg,
        instruction,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const activePromocode = createAsyncThunk(
  "promocodes/activePromocodes",
  async (
    { url, userIdTg, code, mail }: ActivePromocodeParamsInterface,
    thunkAPI
  ) => {
    try {
      const data: any = {
        userIdTg,
        code,
      };

      if (mail) {
        data.mail = mail;
      }

      const res = await axios.post(url, data);
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ url, userIdTg }: FetchUserParamsInterface, thunkAPI) => {
    try {
      const res = await axios.get(url, {
        params: {
          userIdTg,
        },
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }

      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const checkUserData = createAsyncThunk(
  "user/checkUser",
  async ({ initData, url }: CheckUserParamsInterface, thunkAPI) => {
    try {
      const res = await axios.post(url, {
        initData,
      });
      const result: ResponseInterface = res.data;

      return result;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
