import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Block } from "../../../../components/Block.styled";
import { Button } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";

import { TextAriaStyled } from "../../../../components/TextAria.styled";
import { REACT_REVIEW_REPLY_URL } from "../../../../constant";
import { replyReview } from "../../../../redux/slices/actionsReviewsSlice";
import { ViewReviewModeInterface } from "../../../../redux/slices/interface/resp/Review.interface";
import { UserInterface } from "../../../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../../../redux/slices/mySlice";

export const ReviewBlock = ({
  review,
  isAnswered,
}: {
  review: ViewReviewModeInterface;
  isAnswered: boolean;
}) => {
  const dispatch = useDispatch();
  const user: UserInterface | null = useSelector(selectUser);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [reply, setReply] = useState("");
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);

  function switchMenu() {
    setMenuIsOpen(!menuIsOpen);
  }
  function createAnswer(isAnswered:boolean) {
    if (user === null) {
      return;
    }
    dispatch(
      replyReview({
        url: REACT_REVIEW_REPLY_URL,
        userIdTg: user.idTg,
        shopId: review.shopId,
        id: review.id,
        text: reply,
        isAnswered,
      }) as any
    );
    setReply("");
    switchMenu();
  }
  function changeReply(e: any) {
    setReply(e);
  }
  function handleImageClick(src: string) {
    setEnlargedImage(enlargedImage === src ? null : src);
  }
  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <FlexContainer $justify="space-between">
          <FlexContainer $width="fit-content" $align="center">
            {Array.from({ length: 5 }, (v, i) => (
              <FaStar
                key={`${review.id}-${i}-star`}
                color={i < review.productValuation ? "yellow" : "gray"}
              />
            ))}
          </FlexContainer>
          <Button
            $sizeButton="middle"
            onClick={() => switchMenu()}
            disabled={isAnswered && !review.answer?.editable}
          >
            {`${isAnswered ? "Изменить ответ" : "Ответить"}`}
          </Button>
        </FlexContainer>
        <FlexContainer $direction="column" $align="flex-start" $gap="4px">
          <span>
            <b>Имя</b>: {review.userName}
          </span>
          <span>
            <b>Отзыв</b>: {review.text}
          </span>
          <span>
            <b>Продукт</b>: {review.productName}
          </span>
          <span>
            <b>Бренд</b>: {review.brandName}
          </span>
          {isAnswered ? (
            <span>
              <b>Ответ</b>: {review.answer?.text}
            </span>
          ) : (
            ""
          )}
        </FlexContainer>
        {review.photoLinks !== null ? (
          <>
            <ImagesReviewBlock>
              <ImagesBlock>
                {review.photoLinks.map((src, index) => (
                  <ImageCard
                    key={`${review.id}-${index}`}
                    onClick={() => handleImageClick(src.fullSize)}
                  >
                    <img src={src.miniSize} alt={`Slide ${index + 1}`} />
                  </ImageCard>
                ))}
              </ImagesBlock>
            </ImagesReviewBlock>
            {enlargedImage ? (
              <img
                src={enlargedImage}
                alt="enlarged"
                onClick={() => handleImageClick(enlargedImage)}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <p>{review.createdDate}</p>
        <p> {review.shopName}</p>
        {menuIsOpen ? (
          <>
            <TextAriaStyled
              style={{ boxSizing: "border-box" }}
              cacheMeasurements
              value={reply}
              placeholder="Введите ответ"
              onChange={(ev) => changeReply(ev.target.value)}
            />
            <Button $sizeButton="full" onClick={() => createAnswer(isAnswered)}>
              Отправить
            </Button>
          </>
        ) : (
          ""
        )}
      </FlexContainer>
    </Block>
  );
};

const ImagesReviewBlock = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 100%;
`;

const ImagesBlock = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const ImageCard = styled.div`
  display: inline-block;
  margin-right: 10px;
`;
