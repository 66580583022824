import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../components/Button.styled";
import { FlexContainer } from "../../../components/FlexContainer";
import { REACT_SHOP_URL } from "../../../constant";
import { fetchShops } from "../../../redux/slices/actionsMySlice";
import { selectShops, selectUser } from "../../../redux/slices/mySlice";
import BioBlock from "./BioBlock/BioBlock";
import ExWordsBlock from "./ExWords/ExWordsBlock";
import InstructionBlock from "./Instruction/InstructionBlock";
import { PromocodeBlock } from "./Promocode/PromocodeBlock";
import { RegistrationForm } from "./RegistrationBlock/RegistrationForm";
import { ShopBlock } from "./ShopBlock/ShopBlock";
import SignatureBlock from "./Signature/SignatureBlock";

export const MainMyPage = () => {
  const dispatch = useDispatch();
  const [isOpenNewShopForm, setIsOpenNewShopForm] = useState(false);
  const [isOpenSignificationsForms, setIsOpenSignificationsForms] =
    useState(false);

  const { user, shops } = useSelector((state: any) => ({
    user: selectUser(state),
    shops: selectShops(state),
  }));

  useEffect(() => {
    if (user) {
      dispatch(fetchShops({ url: REACT_SHOP_URL, userIdTg: user.idTg }) as any);
    }
  }, [dispatch, user]);

  if (!user) {
    return null;
  }

  return (
    <FlexContainer
      $direction="column"
      $justify="flex-start"
      $align="center"
      $gap="25px"
    >
      <BioBlock />
      {shops.length === 0 ? (
        <RegistrationForm />
      ) : (
        <>
          {shops.map((shop) => (
            <ShopBlock shop={shop} userIdTg={user.idTg} key={shop.id} />
          ))}
          {isOpenNewShopForm && <RegistrationForm />}
          <Button
            $sizeButton={"full"}
            $fontSize={"2em"}
            onClick={() => setIsOpenNewShopForm(!isOpenNewShopForm)}
          >
            {isOpenNewShopForm ? "Свернуть" : "Добавить магазин"}
          </Button>
          <ExWordsBlock />
          {isOpenSignificationsForms &&
            shops.map((shop) => (
              <SignatureBlock shop={shop} userIdTg={user.idTg} key={shop.id} />
            ))}
          <Button
            $sizeButton={"full"}
            $fontSize={"2em"}
            onClick={() =>
              setIsOpenSignificationsForms(!isOpenSignificationsForms)
            }
          >
            {isOpenSignificationsForms ? "Свернуть" : "Добавить подпись"}
          </Button>
          <InstructionBlock />
        </>
      )}
      <PromocodeBlock />
    </FlexContainer>
  );
};
