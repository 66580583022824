import { NavLink as BaseNavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import styled from "styled-components";
import { myTheme } from "../../components/Theme.styled";
import { UserInterface } from "../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../redux/slices/mySlice";

const Menu = () => {
  const user: UserInterface | null = useSelector(selectUser);
  return (
    <MenuStyled>
      <NavLink to="/">Профиль</NavLink>
      <NavLink to="/buypage">Магазин</NavLink>
      <NavLink to="/reviewspage">Отзывы</NavLink>
      {user?.role === "admin" ? <NavLink to="/ad">AD</NavLink> : ""}
    </MenuStyled>
  );
};

export default Menu;

const MenuStyled = styled.nav`
  width: 100%;
  display: flex;
  height: 60px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  background: ${myTheme.colors.dark.primary};
  color: ${myTheme.colors.light.primary};
  border-bottom: 1px solid ${myTheme.colors.light.primary};

  a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;
const NavLink = styled(BaseNavLink)`
  color: ${myTheme.colors.light.primary};
  &.active {
    color: ${myTheme.colors.dark.secondary};
    border: 5px solid ${myTheme.colors.light.secondary};
    border-radius: 7px;
    background-color: ${myTheme.colors.light.secondary};
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    text-decoration: none;
    padding-inline: 10px;
  }
`;
