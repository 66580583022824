import { useSelector } from "react-redux";
import { Block } from "../../../../components/Block.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { UserInterface } from "../../../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../../../redux/slices/mySlice";

const BioBlock = () => {
  const user: UserInterface | null = useSelector(selectUser);
  if (user === null) {
    return <div>Ошибка загрузки данных</div>;
  }
  return (
    <Block>
      <FlexContainer $direction="column" $gap="4px">
        <p>Пользователь: {user.idTg}</p>
        <p>Ответов на GPT: {user.GPTCount}</p>
        {/* <p>Ответов на вопросы: {user.answersCount}</p> */}
        {user.promoDate ? <p>Подписка: {user.promoDate}</p> : <></>}
      </FlexContainer>
    </Block>
  );
};

export default BioBlock;
