import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Центрируем по вертикали */
  align-items: center; /* Центрируем по горизонтали */
  min-height: 100vh; /* Высота экрана */
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  @media (min-width: 768px) {
    margin: 20px auto;
    /* Когда ширина экрана больше 768px, задаем фиксированную ширину */
    max-width: 450px; /* Ограничиваем максимальную ширину как у телефона */
    width: 100%; /* Ширина на 100% относительно родителя */
    border: 2px solid white; /* Это будет ограничение высоты */
    overflow: visible;
    display: block;
  }
`;
