import { createSlice } from "@reduxjs/toolkit";
import {
  activePromocode,
  changeShop,
  changeShopMode,
  changeSignatureMode,
  checkUserData,
  deleteShop,
  fetchShops,
  fetchUser,
  regShop,
  updateExWords,
  updateInstruction,
} from "./actionsMySlice";

import { RootState } from "../store";
import { ShopWithErrorInterface } from "./interface/resp/Shop.interface";
import { UserInterface } from "./interface/resp/User.interface";

interface MyStateInterface {
  shops: Array<ShopWithErrorInterface>;
  isLoading: boolean;
  user: null | UserInterface;
  success: boolean | null;
  dataError: boolean;
}

const initialState: MyStateInterface = {
  shops: [],
  isLoading: false,
  user: null,
  success: null,
  dataError: false,
};

const mySlice = createSlice({
  name: "my",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShops.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchShops.fulfilled,
        (state, action: { payload: Array<ShopWithErrorInterface> }) => {
          state.shops = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(fetchShops.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(regShop.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        regShop.fulfilled,
        (state, action: { payload: ShopWithErrorInterface }) => {
          state.isLoading = false;
          const shopExists =
            state.shops.find((shop) => shop.id === action.payload.id) ===
            undefined;

          if (shopExists) {
            state.shops.push(action.payload);
          }
        }
      )
      .addCase(regShop.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(changeShop.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        changeShop.fulfilled,
        (state, action: { payload: ShopWithErrorInterface }) => {
          state.isLoading = false;
          state.shops = state.shops.map((shop) => {
            if (shop.id === action.payload.id) {
              return action.payload;
            }
            return shop;
          });
        }
      )
      .addCase(changeShop.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(changeShopMode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        changeShopMode.fulfilled,
        (state, action: { payload: ShopWithErrorInterface }) => {
          state.isLoading = false;
          state.shops = state.shops.map((shop) => {
            if (shop.id === action.payload.id) {
              return action.payload;
            }
            return shop;
          });
        }
      )
      .addCase(changeSignatureMode.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(changeSignatureMode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        changeSignatureMode.fulfilled,
        (state, action: { payload: ShopWithErrorInterface }) => {
          state.isLoading = false;
          state.shops = state.shops.map((shop) => {
            if (shop.id === action.payload.id) {
              return action.payload;
            }
            return shop;
          });
        }
      )
      .addCase(changeShopMode.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchUser.fulfilled,
        (state, action: { payload: UserInterface }) => {
          state.isLoading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(checkUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        checkUserData.fulfilled,
        (
          state,
          action: {
            payload: {
              data: { success: true; user: UserInterface } | null;
              hasError: boolean;
              status: number | null;
              errorText: string | null;
            };
          }
        ) => {
          state.isLoading = false;
          state.dataError = action.payload.hasError;
          if (action.payload.data !== null) {
            state.user = action.payload.data.user;
          }
        }
      )
      .addCase(checkUserData.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteShop.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deleteShop.fulfilled,
        (state, action: { payload: { shopId: string } }) => {
          state.isLoading = false;
          state.shops = state.shops.filter(
            (a) => a.id !== action.payload.shopId
          );
        }
      )
      .addCase(deleteShop.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateExWords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateExWords.fulfilled,
        (state, action: { payload: Array<string> }) => {
          state.isLoading = false;
          if (state.user !== null) {
            state.user = { ...state.user, words: action.payload };
          }
        }
      )
      .addCase(updateExWords.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateInstruction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateInstruction.fulfilled,
        (state, action: { payload: string }) => {
          state.isLoading = false;
          if (state.user !== null) {
            if (action.payload === "null") {
              state.user.instruction = null;
            } else {
              state.user = { ...state.user, instruction: action.payload };
            }
          }
        }
      )
      .addCase(updateInstruction.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(activePromocode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        activePromocode.fulfilled,
        (state, action: { payload: boolean }) => {
          state.isLoading = false;
          state.success = action.payload;
        }
      )
      .addCase(activePromocode.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearSuccess } = mySlice.actions;

export const selectShops = (state: RootState) => state.my.shops;
export const selectMySliceIsLoading = (state: RootState) => state.my.isLoading;
export const selectUser = (state: RootState) => state.my.user;
export const selectSuccess = (state: RootState) => state.my.success;
const myReducer = mySlice.reducer;
export default myReducer;
