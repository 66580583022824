import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { FetchReviewsParamsInterface } from "./interface/req/FetchReviewsParams.interface";
import { ReplyToReviewParamsInterface } from "./interface/req/toReviewReplyParams.interface";
import { ResponseInterface } from "./interface/resp/ResponseBase.interface";
import { ErrorCheckerInSlice } from "./tools/ErrorCheckerInSlice";
import { ErrorSwitchInSlice } from "./tools/ErrorSwitchInSlice";

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async (
    { url, userIdTg, isAnswered, take, skip }: FetchReviewsParamsInterface,
    thunkAPI
  ) => {
    try {
      const res: AxiosResponse = await axios.post(url, {
        userIdTg,
        isAnswered,
        take,
        skip,
      });
      const result: ResponseInterface = res.data;
      console.log(result);
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }

      return { data: result.data, isAnswered };
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const replyReview = createAsyncThunk(
  "reviews/replyReview",
  async (
    { url, userIdTg, shopId, id, text,isAnswered }: ReplyToReviewParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        shopId,
        id,
        text,
        userIdTg: userIdTg.toString(),
        isAnswered,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
