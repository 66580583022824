import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  httpStatusCodes,
  REACT_CHANGE_INSTRUCTION_URL,
} from "../../../../constant";

import { Block } from "../../../../components/Block.styled";
import { Button } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { TextAriaStyled } from "../../../../components/TextAria.styled";
import { updateInstruction } from "../../../../redux/slices/actionsMySlice";
import { setError } from "../../../../redux/slices/errorSlice";
import { selectUser } from "../../../../redux/slices/mySlice";

const InstructionBlock = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [word, setWord] = useState<string>("");
  if (!user) {
    return <div>Ошибка загрузки данных</div>;
  }
  const sendInstruction = () => {
    if (word.length <= 0) {
      dispatch(
        setError({
          message:
            "Невозможно отправить пустую инструкцию. Удалите существующую, чтобы применить стандартную инструкцию",
          status: httpStatusCodes.BAD_REQUEST,
        })
      );
    } else {
      dispatch(
        updateInstruction({
          instruction: `Напиши небольшой ответ на отзыв, он должен быть: от 100 до 999 символов, ${word}`,
          userIdTg: user?.idTg,
          url: REACT_CHANGE_INSTRUCTION_URL,
        }) as any
      );
      setWord("");
    }
  };
  const deleteInstruction = () => {
    dispatch(
      updateInstruction({
        instruction: "null",
        userIdTg: user?.idTg,
        url: REACT_CHANGE_INSTRUCTION_URL,
      }) as any
    );
    setWord("");
  };
  const isInstruction: boolean =
    user?.instruction !== undefined && user?.instruction !== null;

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <h2>
          <b>Уникальная инструкция</b>
        </h2>
        {isInstruction
          ? `Ваша инструкция:\n${user.instruction}`
          : `Продолжите инструкцию: Напиши небольшой ответ на отзыв, он должен быть: от 100 до 999 символов, ...`}

        <TextAriaStyled
          style={{ boxSizing: "border-box" }}
          cacheMeasurements
          value={word}
          placeholder="Введите продолжение"
          onChange={(ev) => setWord(ev.target.value)}
        />

        <Button
          $sizeButton="full"
          disabled={word === ""}
          onClick={sendInstruction}
        >
          Отправить
        </Button>
        {isInstruction && (
          <Button
            $sizeButton="full"
            disabled={!isInstruction}
            onClick={deleteInstruction}
          >
            Удалить
          </Button>
        )}
      </FlexContainer>
    </Block>
  );
};

export default InstructionBlock;
